import { t } from '@zupr/i18n'
import classnames from 'classnames'
import getConfig from 'next/config'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import ExternalLink from '../router/link-external'

import AreaContext from '../../context/domain'

import CopyToClipboard from './copy-to-clipboard'
import Dropdown from './dropdown'

import { ReactComponent as Facebook } from '../../../svg/facebook.svg'
import { ReactComponent as Messenger } from '../../../svg/messenger.svg'
import { ReactComponent as Twitter } from '../../../svg/twitter.svg'
import { ReactComponent as Whatsapp } from '../../../svg/whatsapp.svg'

import { ReactComponent as Email } from '../../../svg/email.svg'
import { ReactComponent as LinkIcon } from '../../../svg/link.svg'
import { ReactComponent as ShareIcon } from '../../../svg/share-arrow.svg'

import RouteContext from '../../context/route'
import Tooltip from './tooltip'

import '../../../scss/react/components/action-links.scss'

const { publicRuntimeConfig } = getConfig()

export const Socials = ({ url }) => {
    const [isTouchDevice, setTouchDevice] = useState(false)

    const { host } = useContext(AreaContext)
    const { trackEvent } = useContext(RouteContext)

    useEffect(() => {
        setTouchDevice(!!('ontouchstart' in document.documentElement))
    }, [])

    // add shopping area host
    if (!url?.startsWith('https://')) {
        url = `https://${host}${url || ''}`
    }

    const link = encodeURIComponent(url)

    return (
        <React.Fragment>
            <ExternalLink
                onClick={() => {
                    trackEvent({
                        action: 'clickShareFacebook',
                    })
                }}
                className="btn-social social-facebook"
                href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}
            >
                <Facebook />
            </ExternalLink>
            <ExternalLink
                onClick={() => {
                    trackEvent({
                        action: 'clickShareTwitter',
                    })
                }}
                className="btn-social social-twitter"
                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                    url
                )}`}
            >
                <Twitter />
            </ExternalLink>
            <ExternalLink
                onClick={() => {
                    trackEvent({
                        action: 'clickShareWhatsapp',
                    })
                }}
                className="btn-social social-whatsapp"
                href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                    url
                )}`}
            >
                <Whatsapp />
            </ExternalLink>
            {isTouchDevice && (
                <ExternalLink
                    onClick={() => {
                        trackEvent({
                            action: 'clickShareMessenger',
                        })
                    }}
                    className="btn-social social-messenger"
                    href={`fb-messenger://share/?link=${link}&app_id=${publicRuntimeConfig.NEXT_PUBLIC_FB_ID}`}
                >
                    <Messenger />
                </ExternalLink>
            )}
            <ExternalLink
                onClick={() => {
                    trackEvent({
                        action: 'clickShareMail',
                    })
                }}
                className="btn-social social-mail"
                href={`mailto:?body=${encodeURIComponent(url)}`}
            >
                <Email />
            </ExternalLink>
            <CopyToClipboard text={url} className="btn-social social-mail">
                <LinkIcon />
            </CopyToClipboard>
        </React.Fragment>
    )
}

interface NativeShareProps {
    url: string
    className?: string
    children?: React.ReactNode
}

export const NativeShare = ({ className, children, url }: NativeShareProps) => {
    const { host } = useContext(AreaContext)
    const { trackEvent } = useContext(RouteContext)

    // add shopping area host
    if (!url?.startsWith('https://')) {
        url = `https://${host}${url || ''}`
    }

    const handleShare = useCallback(() => {
        trackEvent({
            action: 'clickShareNative',
        })
        navigator
            .share({
                url,
            })
            .catch(console.warn)
    }, [trackEvent, url])

    return (
        <button className={classnames(className)} onClick={handleShare}>
            {children || (
                <span className="btn-social social-platform">
                    <ShareIcon />
                </span>
            )}
        </button>
    )
}

interface ShareProps {
    url: string
    className?: string
}

const Share = ({ url, className }: ShareProps) => {
    const [open, setOpen] = useState(false)
    const [native, setNative] = useState(false)

    // set native true in browser context
    // handles server side rendering that does not have a concept of navigator
    useEffect(() => {
        if (navigator.share) {
            setNative(true)
        }
    }, [])

    const handleClose = useCallback(() => setOpen(false), [])
    const handleOpen = useCallback(() => setOpen(true), [])

    if (native) {
        return (
            <Tooltip label={t('Share')}>
                <NativeShare url={url} className={className}>
                    <span className="btn-social social-platform">
                        <ShareIcon />
                    </span>
                </NativeShare>
            </Tooltip>
        )
    }

    return (
        <Dropdown open={open} className="share-on-social" onClose={handleClose}>
            <Tooltip label={t('Share')}>
                <button
                    className={classnames(className, {
                        active: open,
                    })}
                    aria-label={t('Share')}
                    onClick={handleOpen}
                >
                    <span className="btn-social social-platform">
                        <ShareIcon />
                    </span>
                </button>
            </Tooltip>
            <Socials url={url} />
        </Dropdown>
    )
}

Share.defaultProps = {
    className: 'btn btn-round share-on-social-button',
}

export default Share
