import React from 'react'
import classnames from 'classnames'
import Tether from 'react-tether'

import ClickOutside from './clickoutside'

import '../../../scss/react/components/tether.scss'
import '../../../scss/react/backoffice/dropdown.scss'

const Dropdown = ({
    children,
    open,
    attachment,
    targetAttachment,
    className,
    onClose,
    offset,
}) => {
    if (React.Children.count(children) !== 2) {
        return <div>{children}</div>
    }

    return (
        <Tether
            attachment={attachment}
            targetAttachment={targetAttachment}
            className={classnames('zupr-dropdown', className)}
            offset={offset}
            constraints={[
                {
                    to: 'window',
                    pin: true,
                },
            ]}
            renderTarget={(ref) =>
                React.cloneElement(React.Children.toArray(children)[0], {
                    ref,
                })
            }
            renderElement={(ref) =>
                open && (
                    <ClickOutside onClickOutside={onClose}>
                        <div ref={ref}>
                            {React.Children.toArray(children)[1]}
                        </div>
                    </ClickOutside>
                )
            }
        />
    )
}

Dropdown.defaultProps = {
    open: false,
    onClose: () => console.warn('tethered dropdown has no onClose function'),
    attachment: 'top center',
    targetAttachment: 'bottom center',
    className: '',
    offset: '-10px 0',
}

export default Dropdown
